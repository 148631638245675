<template>
  <div class="bid_wrap license_wrap">
    <div class="bid_main_wrap">
      <div class="bid_content_wrap">
        <div class="bid_title_wrap">
          <h1 class="title">{{$t('buy_license')}}</h1>
        </div>

        <div class="content_wrap">
          <div class="img_wrap">
            <div class="b_img">
              <img src="@/assets/image/main/bid_img1.jpg"/>
            </div>
            <swiper class="small_img_wrap" ref="swiper" :options="swiperOption2" :slidesPerView="'auto'">
              <swiper-slide class="s_img">
                <img src="@/assets/image/main/bid_img2.jpg"/>
              </swiper-slide>
              <swiper-slide class="s_img">
                <img src="@/assets/image/main/bid_img3.jpg"/>
              </swiper-slide>
              <swiper-slide class="s_img">
                <img src="@/assets/image/main/bid_img4.jpg"/>
              </swiper-slide>
              <swiper-slide class="s_img">
                <img src="@/assets/image/main/bid_img5.jpg"/>
              </swiper-slide>
            </swiper>
          </div>
          <div class="inform_wrap">
            <div class="inform_header">
              <div class="inform_h_header">
                <ul class="thumb_img">
                  <li>
                    <img src="@/assets/image/main/bid_thumb1.png">
                  </li>
                  <li>
                    <img src="@/assets/image/main/bid_thumb2.png">
                  </li>
                  <li>
                    <img src="@/assets/image/main/bid_thumb3.png">
                  </li>
                </ul>
              </div>
              <div class="time_remain"></div>
              <div class="header_title">
                <h3 class="title_left">{{$t('picture')}}</h3>
                <h3 class="title_right">{{$t('input_subject')}}</h3>
              </div>
            </div>
            <div class="inform_content">
              <div class="hashtag_wrap">
                <ul>
                  <li>
                    <span>#해시태그</span>
                  </li>
                  <li>
                    <span>#해시태그</span>
                  </li>
                  <li>
                    <span>#해시태그</span>
                  </li>
                </ul>
              </div>
              <div class="content_top">
                  <div class="content_wrap1">
                    <h3 class="sub_title">Small</h3>
                    <span class="amount">100,000 Mg</span>
                    <div class="detail_wrap">
                      <div>0000 x 0000 px (00.00 x 00.00 in)</div>
                      <div>000 dpi | 00.0MP</div>
                    </div>
                  </div>
                  <div class="content_wrap2">
                    <h3 class="sub_title">{{$t('f_amount')}}</h3>
                    <span>2,000,000 Mg</span>
                  </div>
              </div>

              <div class="search_item">
                <label class="item_text">
                  <div :class="{ active : isActive}" @click="isActive = !isActive" class="check_box_btn"></div>
                  <span class="item_text_span bold" @click="openModal('terms-modal')">{{$t('terms_license')}}</span>
<!--                  <span class="item_text_span ">{{$t('agree_terms2')}}</span>-->
                  <span v-html="$t('agree_terms2')"></span>

                </label>
              </div>
              <div class="btn_wrap">
                <button class="cancel">{{$t('cancel')}}</button>
                <button class="gobid" @click="moveCharge()">{{$t('buy')}}</button>
                <div class="caption_check mt10" ><p>{{$t('caption_check')}}</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <terms-modal></terms-modal>-->
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import alertMixins from "@/mixins/alertMixins";
import TermsModal from "@/components/modal/TermsModal";
import EventBus from "@/utils/event-bus";
export default {
    name: "LicenseWriteDefaultLayout",
    mixins: [alertMixins],
    components: {TermsModal, Swiper, SwiperSlide},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
          termsCheck: false,
          termsMsg: false,
          isActive : false,
          swiperOption: {
            slidesPerView: 4,
            spaceBetween: 8,
            grabCursor:true,
            autoWidth:true,
            loop: true,
          },
          swiperOption2: {
            slidesPerView: 4,
            spaceBetween: 8,
            grabCursor:true,
            autoWidth:true,
            loop: true,
          }
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
      save(type) {
        if (!this.termsCheck) {
          this.termsMsg = true;
          return
        }
        this.termsMsg = false;
        EventBus.$emit(type);
      },
      moveCharge(){
        this.createConfirm({
          content : this.$t('n_e_mingle') + '<br>' + this.$t('to_charge'),
          confirmText : this.$t('yes'),
          cancelText : this.$t('cancel'),
          confirm: () => {
            this.confirmCharge()
          },
          cancel: () => {

          }
        });
      },
      confirmCharge(){
        this.createConfirm({
          content : this.$t('confirm_charge'),
          confirmText : this.$t('yes'),
          cancelText : this.$t('cancel'),
          confirm: () => {
            this.resultCharge();
          },
          cancel: () => {

          }
        });
      },
      resultCharge(){
        this.createConfirm({
          content : this.$t('r_license') + '<br>' + this.$t('check_my'),
          confirmText : this.$t('check_now'),
          cancelText : this.$t('check_later'),
          confirm: () => {

          },
          cancel: () => {

          }
        });
      },
      openModal(name) {
        this.$modal.show(name);
      },

    },
    watch: {},
}
</script>

<style scoped>

</style>